import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EbbBootstrapComponent } from '@app/browser-extension/components/ebb-bootstrap/ebb-bootstrap.component';
import { BulkIconRegistryComponent } from '@app/shared/components/icon/bulk-icon-registry.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ToastComponent } from '@app/shared/components/toast/toast.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { createTranslateLoader } from '@app/shared/dg-translate-loader';
import { NgxHttpClient } from '@app/shared/ngx-http-client';
import {
  AuthService,
  LDFlagsService,
  WebEnvironmentService,
} from '@dg/shared-services';
import { TitleCasePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import { HttpConfigInterceptor } from '@app/browser-extension/core/interceptor/http-config.interceptor';
import { LocalizationService } from '@app/shared/localization.service';
import { ConfigurationService } from '@app/shared/services/configuration/configuration.service';
import { SharedProvidersModule } from '@app/shared/shared-providers.module';
import { TagsSharedModule } from '@app/tags/tags-shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { EbbAuthUserService } from '@app/browser-extension/services/ebb-auth-user.service';
import { initExtensionAppState } from '@app/browser-extension/extension-initializer';
import { ExtensionLocalizationService } from '@app/browser-extension/services/extension-localization.service';
import { tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { EbbAuthService } from '@app/browser-extension/services/ebb-auth.service';
import { OrgBrandingLayoutService } from '@app/orgs/services';
import { ApplicationId } from '@app/shared/web-environment-info';
import { ApolloAngularModule } from '@degreed/apollo-angular';

@NgModule({
  declarations: [EbbBootstrapComponent],
  bootstrap: [EbbBootstrapComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      {
        path: 'degreed-button/app',
        loadChildren: () =>
          import('./browser-extension.module').then(
            (m) => m.BrowserExtensionModule
          ),
      },
    ]),
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      tapToDismiss: false,
      maxOpened: 1, // prevents stacking of toasts messages
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [NgxHttpClient, AuthService],
      },
    }),
    SharedModule,
    SharedProvidersModule,
    TagsSharedModule,
    ApolloAngularModule.forRoot(),
    // standalone components
    BulkIconRegistryComponent,
  ],
  providers: [
    TitleCasePipe,
    NgxHttpClient,
    CookieService,
    ExtensionLocalizationService,
    { provide: LocalizationService, useExisting: ExtensionLocalizationService },
    { provide: AuthService, useExisting: EbbAuthUserService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: ExtensionLocalizationService) => {
        return lastValueFrom(
          localizationService.userExtensionLocale.pipe(
            tap((userLocale) => {
              // lazy-load the locales which we need for localizing dates in some areas of the app
              localizationService.loadLocale(userLocale);
            })
          )
        );
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initExtensionAppState,
      deps: [
        WebEnvironmentService,
        EbbAuthService,
        TranslateService,
        ConfigurationService,
        LocalizationService,
        LDFlagsService,
        OrgBrandingLayoutService,
      ],
      multi: true,
    },
    {
      provide: APP_ID,
      useValue: ApplicationId.Extension,
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class AppBrowserExtensionBootstrapModule {}
